import type { Theme } from "@mui/system"

export const lightTheme: Partial<Theme> = {
  palette: {
    mode: "light",
  },
  components: {
    MuiAppBar: {
      // This is themeColors.Charcoal, which is used for the Card header
      background: "#2C3E50",
    }
  }
}

export const darkTheme: Partial<Theme> = {
  palette: {
    mode: "dark",
    background: {
    },
  },
  components: {
    MuiAppBar: {
      // This is themeColors.Charcoal, which is used for the Card header
      background: "#2C3E50",
    }
  }
}
